@font-face {
  font-family: 'ITV Font';
  src: local('ITV Font'), url(./fonts/itv_reem_web-regular.woff) format('woff');
}

* {
  font-family: 'ITV Font', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

[data-reactroot] {
  display: flex;
}

.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}
button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled].rbc-btn {
  cursor: not-allowed;
}
button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.rbc-calendar {
  box-sizing: border-box;
  border-left: 1px solid #e2e9e9;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}
.rbc-abs-full,
.rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.rbc-day-bg-wrapper + .rbc-day-bg-wrapper {
  border-left: 1px solid #ddd;
}
.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rbc-rtl {
  direction: rtl;
}
.rbc-off-range {
  color: #b3b3b3;
}
.rbc-off-range-bg {
  background-color: #f2f2f2;
}
.rbc-header {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666;
  white-space: nowrap;
  padding: 0 0 10px 10px;
  vertical-align: middle;
  min-height: 0;
  font-size: 12px;
  text-transform: uppercase;
  background-color: white;
  text-align: center;
}
.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}
.rbc-row-content {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 4;
  font-family: Arial, Helvetica, sans-serif;
}
.rbc-today {
  /* background-color: #fff; */
}
.rbc-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  padding: 20px 27px;
  background-color: white;
}
.rbc-toolbar .rbc-toolbar-label {
  width: 100%;
  color: #454545;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
}
.rbc-toolbar button {
  color: #2abec7;
  box-shadow: 0 2px 2px rgba(109, 138, 144, 0.1);
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #e2e9e9;
  padding: 0.5rem 1.1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
  text-transform: capitalize;
  font-size: 14px;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  background-color: #edf4f4;
  border-color: #e2e9e9;
}
.rbc-toolbar button:focus {
  background-color: #edf4f4;
  border-color: #e2e9e9;
}
.rbc-toolbar button:hover {
  background-color: #edf4f4;
  border-color: #e2e9e9;
}
.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}
.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.rbc-btn-group button + button {
  margin-left: -1px;
}
.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}
.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
  margin-left: 10px;
}
.rbc-event {
  cursor: pointer;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  border: 3px solid transparent;
}

.rbc-event:focus {
  outline: none;
}

.rbc-event.rbc-selected {
  border: 3px solid #2abec7;
}
.rbc-event-label {
  font-size: 80%;
}
.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}
.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}
.rbc-row:last-child {
  padding-bottom: 2px;
}
.rbc-row-segment {
  padding: 0 0 0 4px;
  height: 28px;
}
.rbc-row-segment:first-child {
  padding-left: 1px;
}
.rbc-selected-cell {
  background-color: rgba(43, 190, 199, 0.15);
}

.rbc-selected-cell-click {
  background-color: transparent;
  border: 2px solid #2abec7 !important;
  z-index: 6666;
}
.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  white-space: nowrap;
}
.rbc-month-view {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100%;
}
.rbc-month-view .rbc-header {
  border-bottom: 1px solid #ddd;
}

.rbc-rtl .rbc-month-view .rbc-header + .rbc-header {
  border-left-width: 0;
}
.rbc-month-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 10px;
  background-color: #f2f2f2;
}
.rbc-month-row {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  overflow: hidden;
  min-height: 120px;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #e2e9e9;
}
.rbc-date-cell {
  padding-left: 5px;
  padding-top: 5px;
  font-size: 12px;
  color: #454545;
}
.rbc-date-cell.rbc-now {
  font-weight: bold;
  color: #fff;
  background: #2abec7;
  padding: 3px;
}
.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}
.rbc-row-bg {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  overflow: hidden;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #e2e9e9;
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #e2e9e9;
}

.rbc-day-bg-wrapper + .rbc-day-bg-wrapper {
  border-left: 1px solid #ddd;
}

.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}
.rbc-overlay > * + * {
  margin-top: 1px;
}
.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}
.rbc-agenda-view {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  overflow: auto;
}
.rbc-agenda-view table {
  width: 100%;
  border: 1px solid #ddd;
}
.rbc-agenda-view table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
.rbc-agenda-view table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}
.rbc-agenda-view table tbody > tr > td + td {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-agenda-view table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-agenda-view table tbody > tr + tr {
  border-top: 1px solid #ddd;
}
.rbc-agenda-view table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.rbc-rtl .rbc-agenda-view table thead > tr > th {
  text-align: right;
}
.rbc-agenda-time-cell {
  text-transform: lowercase;
}
.rbc-agenda-time-cell .rbc-continues-after:after {
  content: ' »';
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: '« ';
}
.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}
.rbc-agenda-event-cell {
  width: 100%;
}
.rbc-time-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%;
}
.rbc-time-column .rbc-timeslot-group {
  -ms-flex: 1;
  flex: 1;
}
.rbc-timeslot-group {
  border-bottom: 1px solid #ddd;
  min-height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
.rbc-time-gutter,
.rbc-header-gutter {
  -ms-flex: none;
  flex: none;
}
.rbc-label {
  padding: 0 5px;
}
.rbc-day-slot {
  position: relative;
}
.rbc-day-slot .rbc-event {
  border: 1px solid #265985;
  display: -ms-flexbox;
  display: flex;
  max-height: 100%;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow: hidden;
}
.rbc-day-slot .rbc-event-label {
  -ms-flex: none;
  flex: none;
  padding-right: 5px;
  width: auto;
}
.rbc-day-slot .rbc-event-content {
  width: 100%;
  -ms-flex: 1 1 0px;
  flex: 1 1 0;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}
.rbc-time-slot {
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
}
.rbc-time-slot.rbc-now {
  font-weight: bold;
}
.rbc-day-header {
  text-align: center;
}
.rbc-day-slot .rbc-event {
  position: absolute;
  z-index: 2;
}
.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  cursor: default;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  padding: 3px;
}
.rbc-time-view {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  border: 1px solid #ddd;
  min-height: 0;
}
.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
}
.rbc-time-view .rbc-allday-cell {
  width: 100%;
  position: relative;
}
.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}
.rbc-time-view .rbc-row {
  min-height: 20px;
}
.rbc-time-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -ms-flex-direction: column;
  flex-direction: column;
}
.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #ddd;
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #ddd;
}
.rbc-time-header > .rbc-row > * + * {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-time-header > .rbc-row > * + * {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #ddd;
}
.rbc-time-header .rbc-gutter-cell {
  -ms-flex: none;
  flex: none;
}
.rbc-time-header > .rbc-gutter-cell + * {
  width: 100%;
}
.rbc-time-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #ddd;
  overflow-y: auto;
  position: relative;
}
.rbc-time-content > .rbc-time-gutter {
  -ms-flex: none;
  flex: none;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-time-content > .rbc-day-slot {
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rbc-current-time-indicator {
  position: absolute;
  z-index: 1;
  left: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}
.rbc-current-time-indicator::before {
  display: block;
  position: absolute;
  left: -3px;
  top: -3px;
  content: ' ';
  background-color: #74ad31;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}
.rbc-rtl .rbc-current-time-indicator::before {
  left: 0;
  right: -3px;
}

.rbc-addons-dnd .rbc-row-content {
  pointer-events: none;
}
.rbc-addons-dnd .rbc-row-content .rbc-show-more,
.rbc-addons-dnd .rbc-row-content .rbc-event:not(.rbc-addons-dnd-dragging) {
  pointer-events: all;
}
.rbc-addons-dnd .rbc-addons-dnd-over {
  background-color: transparent;
}
.rbc-addons-dnd .rbc-event {
  pointer-events: all;
}
.rbc-addons-dnd.rbc-addons-dnd-is-dragging .rbc-event {
  pointer-events: none;
  opacity: 1;
}

.rbc-addons-dnd-resizable-month-event {
  position: relative;
}
.rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor {
  width: 20px;
  min-height: 20px;
  top: 0;
}
.rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:hover {
  cursor: ew-resize;
}
.rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:first-child {
  position: absolute;
  left: -10px;
}
.rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:last-child {
  position: absolute;
  right: -10px;
}

.react-contextmenu-wrapper {
  height: 100%;
}

.rbc-addons-dnd.rbc-addons-dnd-is-resizing .rbc-day-bg-wrapper {
  z-index: 5;
  opacity: 0.5;
}

.rbc-toolbar {
  position: fixed;
  width: calc(100% - 400px);
  z-index: 20;
}

.rbc-month-view {
  margin-top: 90px;
}

.rbc-month-view:last-child {
  margin-top: 0;
}

.rbc-event-content input {
  font-family: Arial, Helvetica, sans-serif;
}

.rbc-calendar > .rbc-month-view > .rbc-month-header {
  position: fixed;
  width: calc(100% - 400px);
  z-index: 20;
  background-color: white;
  margin-top: -24px;
}

/* React Context Menu */
.react-contextmenu {
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 16px;
  color: #373a3c;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  outline: none;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 7777;
}

.react-contextmenu-item {
  padding: 3px 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #373a3c;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
  cursor: pointer;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #2abec7;
  border-color: #2abec7;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  color: #878a8c;
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item--divider {
  margin-bottom: 3px;
  padding: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
  content: '▶';
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}
